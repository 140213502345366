import {
  Flex,
  Heading,
  Highlight,
  Icon,
  Text,
  TextProps,
  VStack,
} from "@chakra-ui/react"
import WhiteLionIcon from "@public/images/icon.svg"
import { AnimatePresence, motion } from "framer-motion"
import { MDXRemote, MDXRemoteSerializeResult } from "next-mdx-remote"

const HomeAbout = ({ content }: { content: MDXRemoteSerializeResult }) => {
  return (
    <AnimatePresence>
      <motion.div
        key={"partners"}
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ ease: "easeInOut", duration: 1 }}
      >
        <VStack
          as={"section"}
          spacing={6}
          id={"about"}
          my={{ base: 4, md: 10 }}
          scrollMarginTop={20}
        >
          <Flex
            justifyContent={"center"}
            align={"center"}
          >
            <Icon
              as={WhiteLionIcon}
              w={20}
              h={20}
              me={4}
            />
            <Heading
              as={"h1"}
              fontSize={{ base: "4xl", md: "6xl" }}
              textAlign={"center"}
            >
              <Highlight
                query={content.frontmatter.highlight as string}
                styles={{
                  px: "4",
                  py: "1",
                  rounded: "full",
                  bg: "brand.100",
                  color: "white",
                }}
              >
                {content.frontmatter.title as string}
              </Highlight>
            </Heading>
          </Flex>

          <MDXRemote
            {...content}
            components={{
              p: (props: TextProps) => (
                <Text
                  as={"p"}
                  fontWeight={"500"}
                  fontSize={{ base: "lg", md: "2xl" }}
                  textAlign={{ base: "start", md: "center" }}
                  {...props}
                />
              ),
            }}
          />
        </VStack>
      </motion.div>
    </AnimatePresence>
  )
}

export default HomeAbout
