import { Box, BoxProps } from "@chakra-ui/react"
import { AnimatePresence, motion } from "framer-motion"
import { useEffect, useState } from "react"

interface TextFadeProps extends BoxProps {
  texts: string[]
  interval: number
}

const FadeText = ({ texts, interval, ...props }: TextFadeProps) => {
  const [currentTextIndex, setCurrentTextIndex] = useState(0)

  useEffect(() => {
    const timeoutId = setInterval(() => {
      setCurrentTextIndex((prevIndex) => (prevIndex + 1) % texts.length)
    }, interval)

    return () => {
      clearInterval(timeoutId)
    }
  }, [texts, interval])

  return (
    <Box {...props}>
      <AnimatePresence mode={"wait"}>
        <motion.div
          key={currentTextIndex}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.5 }}
        >
          {texts[currentTextIndex]}
        </motion.div>
      </AnimatePresence>
    </Box>
  )
}

export default FadeText
