export interface NavItem {
  label: string
  href: string
}

export const NavItems: Array<NavItem> = [
  {
    label: "About",
    href: "#about",
  },
  {
    label: "Products & Services",
    href: "#products-and-services",
  },
  {
    label: "Partners",
    href: "#partners",
  },
  {
    label: "Contact",
    href: "#contact",
  },
]
