import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputLeftElement,
  Textarea,
  useColorModeValue,
  VStack,
} from "@chakra-ui/react"
import { Player } from "@lottiefiles/react-lottie-player"
import contactSuccess from "@public/lottie/email-confirmation-sent.json"
import { useState } from "react"
import { useForm } from "react-hook-form"
import { BsPerson } from "react-icons/bs"
import { MdOutlineEmail } from "react-icons/md"

export type FormValues = {
  full_name: string
  email: string
  message: string
}

const ContactForm = () => {
  const [formSuccess, setFormSuccess] = useState<boolean>(false)
  const [formLoading, setFormLoading] = useState<boolean>(false)
  const placeholderColor = useColorModeValue("brand.500", "brand.50")
  const bgColor = useColorModeValue("gray.100", "blackAlpha.300")
  const textColor = useColorModeValue("brand.900", "brand.50")

  const defaultValues = {
    full_name: "",
    email: "",
    message: "",
  }

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<FormValues>({
    defaultValues: defaultValues,
  })

  const onSubmit = async (values: FormValues) => {
    setFormLoading(true)
    await fetch("/api/contact", {
      method: "POST",
      body: JSON.stringify(values),
      headers: {
        "Content-Type": "application/json",
      },
    }).then(() => {
      setFormSuccess(true)
      setFormLoading(false)
    })
  }

  if (formSuccess) {
    return (
      <Flex
        alignItems={"center"}
        direction={"column"}
        width={"full"}
      >
        <Box width={40}>
          <Player
            autoplay
            loop={false}
            controls={false}
            speed={3}
            src={contactSuccess}
            style={{ stroke: "red", fill: "red" }}
          />
        </Box>

        <Box>
          {"Thank you for contacting us. Our team will get back to you soon."}
        </Box>
      </Flex>
    )
  }

  return (
    <Box
      bg={bgColor}
      borderRadius={"lg"}
      p={{ base: 4, md: 8 }}
      mt={{ base: 8, md: 0 }}
      color={textColor}
      w={{ base: "full", md: "50%" }}
    >
      <VStack
        spacing={5}
        as={"form"}
        onSubmit={handleSubmit(onSubmit)}
      >
        <FormControl isInvalid={!!errors.full_name}>
          <FormLabel>{"Name"}</FormLabel>

          <InputGroup>
            <InputLeftElement>
              <BsPerson />
            </InputLeftElement>
            <Input
              type={"text"}
              id={"full_name"}
              placeholder={
                errors.full_name ? errors.full_name.message : "Full Name"
              }
              borderColor={"brand.500"}
              _placeholder={{
                color: placeholderColor,
              }}
              {...register("full_name", {
                required: "Full name is required",
              })}
            />
          </InputGroup>
        </FormControl>

        <FormControl isInvalid={!!errors.email}>
          <FormLabel>{"Email"}</FormLabel>

          <InputGroup>
            <InputLeftElement>
              <MdOutlineEmail />
            </InputLeftElement>
            <Input
              type={"email"}
              id={"email"}
              placeholder={
                errors.email ? errors.email.message : "Email Address"
              }
              borderColor={"brand.500"}
              _placeholder={{
                color: placeholderColor,
              }}
              {...register("email", {
                required: "Email is required",
                pattern: {
                  value:
                    // eslint-disable-next-line no-control-regex
                    /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/,
                  message: "Email is not valid",
                },
              })}
            />
          </InputGroup>
        </FormControl>

        <FormControl isInvalid={!!errors.message}>
          <FormLabel>{"Message"}</FormLabel>

          <Textarea
            id={"message"}
            placeholder={errors.message ? errors.message.message : "Message"}
            rows={6}
            resize={"none"}
            borderColor={"brand.500"}
            _placeholder={{
              color: placeholderColor,
            }}
            {...register("message", {
              required: "Message is required",
            })}
          />
        </FormControl>

        <Button
          colorScheme={"brand"}
          isLoading={isSubmitting || formLoading}
          type={"submit"}
        >
          {"Send Message"}
        </Button>
      </VStack>
    </Box>
  )
}

export default ContactForm
