import {
  Box,
  Container,
  Divider,
  useColorModeValue,
  VStack,
} from "@chakra-ui/react"
import Contact from "@components/contact/Contact"
import HomeAbout from "@components/home/About"
import HomeHero from "@components/home/Hero"
import Layout from "@components/home/Layout"
import Partners from "@components/home/Partners"
import ProductsServices from "@components/home/ProductsServices"
import BooksCollage from "@public/images/books-collage.png"
import fs from "fs"
import { GetStaticProps, InferGetStaticPropsType } from "next"
import { serialize } from "next-mdx-remote/serialize"
import { join } from "path"

export default function Home({
  whitelion,
  products,
}: InferGetStaticPropsType<typeof getStaticProps>) {
  return (
    <Layout>
      <HomeHero />
      <Box
        bgImage={`url(${BooksCollage.src})`}
        bgSize={"cover"}
        bgPosition={"center"}
        bgRepeat={"no-repeat"}
        bgAttachment={{ base: "scroll", md: "fixed" }}
        filter={"grayscale(50%) sepia(50%)"}
        w={"full"}
        h={"50vh"}
      />
      <Box
        bg={useColorModeValue("white", "black")}
        color={useColorModeValue("brand.900", "brand.50")}
      >
        <Container
          as={VStack}
          maxW={"6xl"}
          spacing={10}
          justify={"center"}
          align={"center"}
          py={10}
          overflow={"hidden"}
        >
          <HomeAbout content={whitelion} />

          <Divider bgColor={"brand.500"} />

          <ProductsServices products={products} />

          <Divider bgColor={"brand.500"} />

          <Partners />

          <Divider bgColor={"brand.500"} />

          <Contact />
        </Container>
      </Box>
    </Layout>
  )
}

export const getStaticProps: GetStaticProps = async () => {
  const dataDirectory = join(process.cwd(), "_data")

  const whitelion = await serialize(
    fs.readFileSync(join(dataDirectory, `whitelion.md`), "utf8"),
    { parseFrontmatter: true }
  )

  const gzb = await serialize(
    fs.readFileSync(join(dataDirectory, `gzb.md`), "utf8"),
    { parseFrontmatter: true }
  )
  const k12 = await serialize(
    fs.readFileSync(join(dataDirectory, `k12.md`), "utf8"),
    { parseFrontmatter: true }
  )
  const higherEd = await serialize(
    fs.readFileSync(join(dataDirectory, `higher-ed.md`), "utf8"),
    { parseFrontmatter: true }
  )
  const libraries = await serialize(
    fs.readFileSync(join(dataDirectory, `libraries.md`), "utf8"),
    { parseFrontmatter: true }
  )

  return {
    props: {
      whitelion,
      products: {
        gzb,
        k12,
        higherEd,
        libraries,
      },
    },
  }
}
