import {
  Box,
  chakra,
  Container,
  Icon,
  Link,
  Stack,
  Text,
  useColorModeValue,
  VisuallyHidden,
} from "@chakra-ui/react"
import { NavItems } from "@components/nav/data"
import { handleClickScroll } from "@components/nav/Navbar"
import WhiteLionLogo from "@public/images/logo.svg"
import NextLink from "next/link"
import { ReactNode } from "react"
import { FaLinkedin } from "react-icons/fa"

const SocialButton = ({
  children,
  label,
  href,
}: {
  children: ReactNode
  label: string
  href: string
}) => {
  return (
    <chakra.button
      bg={useColorModeValue("blackAlpha.100", "whiteAlpha.100")}
      rounded={"full"}
      w={8}
      h={8}
      cursor={"pointer"}
      as={"a"}
      href={href}
      display={"inline-flex"}
      alignItems={"center"}
      justifyContent={"center"}
      transition={"background 0.3s ease"}
      _hover={{
        bg: useColorModeValue("blackAlpha.200", "whiteAlpha.200"),
      }}
    >
      <VisuallyHidden>{label}</VisuallyHidden>
      {children}
    </chakra.button>
  )
}

const Footer = () => {
  return (
    <Box
      bg={useColorModeValue("brand.50", "brand.900")}
      color={useColorModeValue("brand.700", "brand.50")}
    >
      <Container
        as={Stack}
        maxW={"6xl"}
        py={4}
        spacing={4}
        justify={"center"}
        align={"center"}
      >
        <Link
          as={NextLink}
          href={"/"}
        >
          <Icon
            as={WhiteLionLogo}
            w={60}
            h={20}
          />
        </Link>
        <Stack
          direction={"row"}
          spacing={{ base: 2, md: 4 }}
          fontSize={{ base: "xs", md: "md" }}
        >
          {NavItems.map((navItem, index) => (
            <Link
              key={index}
              href={navItem.href}
              onClick={(e) => {
                e.preventDefault()
                handleClickScroll(navItem.href)
              }}
            >
              {navItem.label}
            </Link>
          ))}
        </Stack>
      </Container>

      <Box
        borderTopWidth={1}
        borderStyle={"solid"}
        borderColor={useColorModeValue("brand.200", "brand.700")}
      >
        <Container
          as={Stack}
          maxW={"6xl"}
          fontSize={{ base: "xs", md: "sm" }}
          py={4}
          direction={{ base: "column", md: "row" }}
          spacing={4}
          justify={{ base: "center", md: "space-between" }}
          align={{ base: "center", md: "center" }}
        >
          <Text>
            {"© "}
            {new Date().getFullYear()}
            {" White Lion General Trading LLC. All rights reserved."}
          </Text>
          <Stack
            direction={"row"}
            spacing={6}
          >
            <SocialButton
              label={"Linkedin"}
              href={"https://www.linkedin.com"}
            >
              <FaLinkedin />
            </SocialButton>
          </Stack>
        </Container>
      </Box>
    </Box>
  )
}

export default Footer
