import { Box, Flex } from "@chakra-ui/react"
import FadeText from "@components/home/FadeText"
import HeroImage from "@public/images/hero-image.jpg"

const HomeHero = () => {
  return (
    <Box
      bgImage={`url(${HeroImage.src})`}
      bgSize={"cover"}
      bgPosition={"center"}
      bgRepeat={"no-repeat"}
      bgAttachment={{ base: "scroll", md: "fixed" }}
      w={"full"}
      h={"100vh"}
    >
      <Flex
        justify={"center"}
        align={"center"}
        w={"full"}
        h={"full"}
      >
        <FadeText
          fontSize={{ base: "7xl", md: "8xl" }}
          fontWeight={"bold"}
          color={"white"}
          interval={3000}
          texts={["imagine.", "empower.", "educate."]}
        />
      </Flex>
    </Box>
  )
}

export default HomeHero
