import {
  Box,
  Flex,
  Heading,
  Icon,
  Image,
  Link,
  useColorModeValue,
} from "@chakra-ui/react"
import GzbLogo from "@public/images/gzb-logo.ae.svg"
import { AnimatePresence, motion } from "framer-motion"
import { MDXRemote, MDXRemoteSerializeResult } from "next-mdx-remote"
import { ReactNode } from "react"

type ConditionalWrapperProps = {
  condition: boolean
  wrapper: (children: ReactNode) => JSX.Element
  children: JSX.Element
}
const ConditionalWrapper = ({
  condition,
  wrapper,
  children,
}: ConditionalWrapperProps) => (condition ? wrapper(children) : children)

const HomeBlock = ({
  content,
  direction = "row",
}: {
  content: MDXRemoteSerializeResult
  direction?: "row" | "row-reverse"
}) => {
  return (
    <AnimatePresence>
      <motion.div
        key={content.frontmatter.title as string}
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ ease: "easeInOut", duration: 1 }}
      >
        <Flex
          flexDirection={{ base: "column", md: direction }}
          justifyContent={"space-between"}
        >
          <Flex
            flex={"1"}
            marginRight={"3"}
            position={"relative"}
            alignItems={"center"}
          >
            <Box
              width={{ base: "100%", sm: "85%" }}
              zIndex={"2"}
              marginLeft={{ base: "0", sm: "5%" }}
              marginTop={"5%"}
            >
              <Image
                borderRadius={"lg"}
                src={content?.frontmatter.image as string}
                alt={content.frontmatter.title as string}
                objectFit={"contain"}
              />
            </Box>
            <Box
              zIndex={"1"}
              width={"100%"}
              position={"absolute"}
              height={"100%"}
            >
              <Box
                bgGradient={useColorModeValue(
                  "radial(brand.600 1px, transparent 1px)",
                  "radial(brand.300 1px, transparent 1px)"
                )}
                backgroundSize={"20px 20px"}
                opacity={"0.4"}
                height={"100%"}
              />
            </Box>
          </Flex>
          <Box
            display={"flex"}
            flex={"1"}
            flexDirection={"column"}
            justifyContent={"center"}
            marginTop={{ base: "3", sm: "0" }}
          >
            <Heading marginTop={{ base: 10, md: 0 }}>
              <ConditionalWrapper
                condition={content.frontmatter.externalLink as boolean}
                wrapper={(children) => (
                  <Link
                    textDecoration={"none"}
                    _hover={{ textDecoration: "none" }}
                    href={content.frontmatter.externalLink as string}
                    target={"_blank"}
                  >
                    {children}
                  </Link>
                )}
              >
                {(content.frontmatter.showGzbLogo as boolean) ? (
                  <Icon
                    as={GzbLogo}
                    aria-label={content.frontmatter.title as string}
                    w={32}
                    h={12}
                  />
                ) : (
                  <>{content.frontmatter.title as string}</>
                )}
              </ConditionalWrapper>
            </Heading>
            <Box mt={4}>
              <MDXRemote {...content} />
            </Box>
          </Box>
        </Flex>
      </motion.div>
    </AnimatePresence>
  )
}

export default HomeBlock
