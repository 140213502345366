import {
  Box,
  Collapse,
  Flex,
  Icon,
  IconButton,
  Link,
  Stack,
  Text,
  useColorMode,
  useColorModeValue,
  useDisclosure,
  useToken,
} from "@chakra-ui/react"
import { NavItem, NavItems } from "@components/nav/data"
import WhiteLionLogo from "@public/images/logo.svg"
import NextLink from "next/link"
import { GiHamburgerMenu } from "react-icons/gi"
import { IoMdClose } from "react-icons/io"
import { MdOutlineDarkMode, MdOutlineLightMode } from "react-icons/md"

export default function Navbar() {
  const { isOpen, onToggle } = useDisclosure()
  const { colorMode, toggleColorMode } = useColorMode()
  const brandColor = useToken("colors", "brand.500")

  return (
    <Box
      position={"fixed"}
      w={"full"}
      bg={"transparent"}
      zIndex={3}
    >
      <Flex
        bgColor={brandColor + "cc"}
        color={"white"}
        minH={"60px"}
        py={{ base: 2 }}
        px={{ base: 4 }}
        align={"center"}
      >
        <Flex
          flex={{ base: 1, md: "auto" }}
          ml={{ base: -2 }}
          display={{ base: "flex", md: "none" }}
        >
          <IconButton
            color={"white"}
            onClick={onToggle}
            icon={isOpen ? <IoMdClose /> : <GiHamburgerMenu />}
            variant={"ghost"}
            aria-label={"Toggle Navigation"}
            _hover={{
              bgColor: "transparent",
            }}
          />
        </Flex>
        <Flex
          flex={{ base: 1 }}
          justify={{ base: "center", md: "start" }}
          align={"center"}
        >
          <Link
            as={NextLink}
            href={"/"}
          >
            <Icon
              as={WhiteLionLogo}
              w={44}
              h={10}
              color={"white"}
              display={"block"}
            />
          </Link>

          <Flex
            display={{ base: "none", md: "flex" }}
            align={"center"}
            ml={10}
          >
            <DesktopNav />
          </Flex>
        </Flex>

        <Stack
          flex={{ base: 1, md: 0 }}
          justify={"flex-end"}
          direction={"row"}
          spacing={6}
        >
          <IconButton
            variant={"ghost"}
            title={"Toggle Dark Mode"}
            aria-label={"toggle color mode"}
            onClick={toggleColorMode}
            color={"white"}
            icon={
              colorMode === "light" ? (
                <MdOutlineDarkMode />
              ) : (
                <MdOutlineLightMode />
              )
            }
            _hover={{
              bgColor: "transparent",
            }}
          />
        </Stack>
      </Flex>

      <Collapse
        in={isOpen}
        animateOpacity
      >
        <MobileNav />
      </Collapse>
    </Box>
  )
}

export const handleClickScroll = (href: string) => {
  const element = document.getElementById(href.replace("#", ""))
  if (element) {
    element.scrollIntoView({ behavior: "smooth" })
  }
}

const DesktopNav = () => {
  const linkColor = "white"
  const linkHoverColor = "brand.50"

  return (
    <Stack
      direction={"row"}
      spacing={4}
    >
      {NavItems.map((navItem) => (
        <Box key={navItem.label}>
          <Link
            p={2}
            href={navItem.href}
            onClick={(e) => {
              e.preventDefault()
              handleClickScroll(navItem.href)
            }}
            fontSize={"sm"}
            fontWeight={500}
            color={linkColor}
            _hover={{
              textDecoration: "none",
              color: linkHoverColor,
            }}
          >
            {navItem.label}
          </Link>
        </Box>
      ))}
    </Stack>
  )
}

const MobileNav = () => {
  return (
    <Stack
      bg={useColorModeValue("brand.50", "brand.800")}
      p={4}
      display={{ md: "none" }}
    >
      {NavItems.map((navItem) => (
        <MobileNavItem
          key={navItem.label}
          {...navItem}
        />
      ))}
    </Stack>
  )
}

const MobileNavItem = ({ label, href }: NavItem) => {
  return (
    <Stack spacing={4}>
      <Flex
        py={2}
        as={Link}
        onClick={(e) => {
          e.preventDefault()
          handleClickScroll(href)
        }}
        href={href ?? "#"}
        justify={"space-between"}
        align={"center"}
        _hover={{
          textDecoration: "none",
        }}
      >
        <Text
          fontSize={"sm"}
          fontWeight={500}
          _hover={{
            color: useColorModeValue("brand.800", "brand.50"),
          }}
        >
          {label}
        </Text>
      </Flex>
    </Stack>
  )
}
