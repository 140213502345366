import { Divider, VStack } from "@chakra-ui/react"
import HomeBlock from "@components/home/Block"
import { MDXRemoteSerializeResult } from "next-mdx-remote"

const ProductsServices = ({
  products,
}: {
  products: {
    gzb: MDXRemoteSerializeResult
    k12: MDXRemoteSerializeResult
    higherEd: MDXRemoteSerializeResult
    libraries: MDXRemoteSerializeResult
  }
}) => {
  return (
    <VStack
      as={"section"}
      spacing={10}
      id={"products-and-services"}
      scrollMarginTop={24}
    >
      <HomeBlock content={products.gzb} />

      <Divider bgColor={"brand.500"} />

      <HomeBlock
        content={products.k12}
        direction={"row-reverse"}
      />

      <Divider bgColor={"brand.500"} />

      <HomeBlock content={products.higherEd} />

      <Divider bgColor={"brand.500"} />

      <HomeBlock
        content={products.libraries}
        direction={"row-reverse"}
      />
    </VStack>
  )
}

export default ProductsServices
