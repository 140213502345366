import { Box, BoxProps } from "@chakra-ui/react"
import Footer from "@components/home/Footer"
import Navbar from "@components/nav/Navbar"

const Layout = ({ children }: BoxProps) => {
  return (
    <Box position={"relative"}>
      <Navbar />
      {children}
      <Footer />
    </Box>
  )
}

export default Layout
