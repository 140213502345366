import { Box, Flex, Heading, useColorModeValue } from "@chakra-ui/react"
import ContactForm from "@components/contact/ContactForm"
import ContactInfo from "@components/contact/ContactInfo"
import { AnimatePresence, motion } from "framer-motion"

const Contact = () => {
  return (
    <Box
      as={"section"}
      id={"contact"}
      py={{ base: 2, md: 4 }}
      px={{ base: 1, md: 2 }}
      w={"full"}
      scrollMarginTop={10}
    >
      <AnimatePresence>
        <motion.div
          key={"partners"}
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ ease: "easeInOut", duration: 1 }}
        >
          <Heading
            as={"h2"}
            fontSize={{ base: "2xl", md: "5xl" }}
            textAlign={"center"}
            mb={8}
          >
            {"Contact"}
          </Heading>
          <Box textAlign={"center"}>
            {"Please reach out to us for any inquiries, comments, or concerns."}
          </Box>
          <Flex
            marginTop={{ base: "5", sm: "10" }}
            flexDirection={{ base: "column", md: "row" }}
            justifyContent={"space-around"}
            bg={useColorModeValue("gray.50", "blackAlpha.100")}
            rounded={"xl"}
            p={{ base: 4, md: 10 }}
          >
            <ContactInfo />
            <ContactForm />
          </Flex>
        </motion.div>
      </AnimatePresence>
    </Box>
  )
}

export default Contact
