import { Box, Heading, Icon, SimpleGrid } from "@chakra-ui/react"
import Bloomsbury from "@public/images/partners/bloomsbury.svg"
import Elsevier from "@public/images/partners/elsevier.svg"
import Gazelle from "@public/images/partners/gazelle.svg"
import Hachette from "@public/images/partners/hachette.svg"
import MacMillan from "@public/images/partners/macmillan.svg"
import Oxford from "@public/images/partners/oxford.svg"
import Penguin from "@public/images/partners/penguin.svg"
import Sage from "@public/images/partners/sage.svg"
import Springer from "@public/images/partners/springer.svg"
import TaylorFrancis from "@public/images/partners/taylor-francis.svg"
import Wiley from "@public/images/partners/wiley.svg"
import Workman from "@public/images/partners/workman.svg"
import { AnimatePresence, motion } from "framer-motion"

const PartnersList = [
  {
    name: "Bloomsbury",
    logo: Bloomsbury,
  },
  {
    name: "Elsevier",
    logo: Elsevier,
  },
  {
    name: "Gazelle",
    logo: Gazelle,
  },
  {
    name: "Hachette",
    logo: Hachette,
  },
  {
    name: "MacMillan",
    logo: MacMillan,
  },
  {
    name: "Oxford",
    logo: Oxford,
  },
  {
    name: "Penguin",
    logo: Penguin,
  },
  {
    name: "Sage",
    logo: Sage,
  },
  {
    name: "Springer",
    logo: Springer,
  },
  {
    name: "TaylorFrancis",
    logo: TaylorFrancis,
  },
  {
    name: "Wiley",
    logo: Wiley,
  },
  {
    name: "Workman",
    logo: Workman,
  },
]

const Partners = () => {
  return (
    <AnimatePresence>
      <motion.div
        key={"partners"}
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ ease: "easeInOut", duration: 1 }}
      >
        <Box
          as={"section"}
          id={"partners"}
          position={"relative"}
          py={{ base: 2, md: 4 }}
          px={{ base: 1, md: 2 }}
          scrollMarginTop={10}
        >
          <Box
            position={"relative"}
            zIndex={1}
          >
            <Heading
              as={"h2"}
              fontSize={{ base: "2xl", md: "5xl" }}
              textAlign={"center"}
              mb={8}
            >
              {"Partners"}
            </Heading>

            <SimpleGrid
              columns={{ base: 2, md: 4, lg: 6 }}
              spacing={8}
              justifyItems={"center"}
              alignItems={"center"}
            >
              {PartnersList.map((partner, index) => (
                <Icon
                  key={index}
                  as={partner.logo}
                  aria-label={partner.name}
                  w={40}
                  h={20}
                  m={4}
                />
              ))}
            </SimpleGrid>
          </Box>
        </Box>
      </motion.div>
    </AnimatePresence>
  )
}

export default Partners
